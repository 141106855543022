<template>
    <b-row>
        <b-col md="2"></b-col>
        <b-col md="8">
            <b-card bg-variant="light">
                <b-form @submit="onSubmit">
                    <b-form-group horizontal breakpoint="lg" label="Change Password" label-size="lg"
                                  label-class="font-weight-bold pt-0" class="mb-0">
                        <div  class="mt-5">
                          <label for="">Current Password</label>
                            <b-form-input type="password" v-model="form.current" id="current_password"
                                          name="current_password"
                                          data-vv-as="Current Password" v-validate="'required'"></b-form-input>
                            <span v-show="errors.has('current_password')" class="error-msg float-left mb-2 mt-2">{{ errors.first('current_password') }}</span>
                        </div>
                        <div class="mt-5" >
                          <label for="">New Password</label>
                            <b-form-input type="password" v-model="form.new" id="new_password" name="new_password"
                                          ref="new_password" data-vv-as="New Password"
                                          v-validate="'required|min:6'"></b-form-input>
                            <span v-show="errors.has('new_password')" class="error-msg float-left mb-2 mt-2">{{ errors.first('new_password') }}</span>
                        </div>
                        <div class="mt-5">
                          <label  for="">Confirm Password</label>
                            <b-form-input required type="password" v-model="form.new_confirmation" id="confirm_password"
                                          name="confirm_password" data-vv-as="Confirm Password"
                                          v-validate="'confirmed:new_password'"></b-form-input>
                            <span v-show="errors.has('confirm_password')" class="error-msg float-left mb-2 mt-2">{{ errors.first('confirm_password') }}</span>
                        </div>
                        <div class="mt-3 float-right">
                            <b-button type="submit" :disabled="errors.any()" variant="primary">Submit</b-button>
                        </div>
                    </b-form-group>
                </b-form>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
export default {
  name: "Settings",
  data() {
    return {
      form: {
        current: "",
        new: "",
        new_confirmation: ""
      }
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.$validator.validate().then(result => {
        if (!result) return;
        this.$store
          .dispatch("changePassword", this.form)
          .then(response => {
            this.$notify("Success!", response.data.error_msg, "success");
            this.$store.commit("set_busy", false);
          })
          .catch(error => {
            this.$store.commit("set_busy", false);
            if (error.response.data.error === true) {
              this.$notify("Error!", error.response.data.error_msg, "danger");
            }
          });
        setTimeout(() => {
          this.onReset();
        }, 1000);
      });
    },
    onReset() {
      /* Reset our form values */
      this.form.current = "";
      this.form.new = "";
      this.form.new_confirmation = "";
      setTimeout(() => {
        this.errors.clear();
      }, 20);
    }
  }
};
</script>

<style scoped>
.error-msg {
  color: tomato;
  font-size: 12px;
}
</style>
